import React from "react";
import { Button as MuiButton } from "@mui/material";

const QtyButton = (props) => {
  const { text, size, color, varient, onClick, ...others } = props;
  return (
    <MuiButton
      disableElevation
      sx={{ padding: 0, minWidth: "25px", marginRight: 0.5 }}
      size={size || "small"}
      color={color || "primary"}
      variant={varient || "contained"}
      onClick={onClick}
      {...others}
    >
      {text}
    </MuiButton>
  );
};

export default QtyButton;