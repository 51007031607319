import React from "react";
import Controls from "../components/Controls";
import SearchIcon from "@mui/icons-material/Search";
import {
  Container,
  Grid,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TableContainer,
  Table,
  TableBody
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {listMenus} from "../actions/menuActions"
import { listCategories } from "../actions/categoryActions";

const PosDashboard = () => {
  const dispatch = useDispatch();

  // List Menus
  const menusList = useSelector((state) => state.menusList);
  const { menus, loading } = menusList;

  // List Categories
  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  React.useEffect(() => {
    dispatch(listMenus())
    dispatch(listCategories())
   
  }, [ dispatch]);

  const [searchValue, setSearchValue] = React.useState("");
  const [category, setCategory] = React.useState("");

  const handleCategory = (category) => {
    setCategory(category);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredItems =
    menus &&
    menus.filter((item) => {
      if (category && item.category._id !== category) {
        return false;
      }

      if (
        searchValue &&
        !item.name.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return false;
      }

      return true;
    });


  return (

<Container>
{loading ? (
      <Controls.MuiLoading />
    ) : (
      <React.Fragment>
     <Box sx={{ display: "flex",  }}>

<Controls.Input
  icon={<SearchIcon />}
  size="small"
  label="Search Items..."
  type="search"
  value={searchValue}
  onChange={handleChange}
/>
            <Box sx={{ minWidth: 180, marginTop: 1, marginLeft: 1 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="filterByCategory">
                By Category
              </InputLabel>
              <Select
                labelId="filterByCategory"
                label="By Category"
              >
                <MenuItem
                  value="All Categories"
                  onClick={(e) => {
                    setCategory(null);
                  }}
                >
                  All Menus
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem
                    key={category._id}
                    value={category._id}
                    onClick={(e) => {
                      handleCategory(category._id);
                    }}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>  
</Box>
{/* End Filter & Search */}

<TableContainer sx={{  maxHeight: "75vh" }}>
            <Table aria-label="Products" size="small" stickyHeader>
              <TableBody>
                <Grid container spacing={1}>
  {filteredItems.map((menu) => (
    <Grid item xs={12} sm={6} key={menu._id}>
      <Controls.AddToCart menu={menu} />
    </Grid>
  ))}
</Grid>
              </TableBody>
            </Table>
          </TableContainer>
      </React.Fragment>
    )}
</Container>
  );
};

export default PosDashboard;

