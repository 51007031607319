// import Input from "./MuiInput/Input";
// import Select from "./MuiSelect/Select";
// import Button from "./MuiButton/Button";
// import MuiAlert from "./Alert/MuiAlert";
// import MuiNotification from "./Notification/MuiNotification";
// import ConfirmDialog from "./MuiDialog/ConfirmDialog";
// import Popup from "./MuiModel/Popup";
// import PopupOrder from "./MuiModel/PopupOrder";
// import CategorySelect from "./MuiSelect/CategorySelect";
// import MuiSelect from "./MuiSelect/MuiSelect";
// import MuiFab from "./FabButton/MuiFab";
// import MuiChip from "./Chip/MuiChip";
// import MainButton from "./MuiButton/MainButton";
// import Paginate from "./MuiPaginate/Paginate";
// import MuisideMenu from "./Sidemenu/MuisideMenu";


import Input from './MuiInput/MuiInput'
import MuiSelect from './MuiSelect/MuiSelect'
import Button from './MuiButton/Button'
import Alert from './MuiAlert/MuiAlert'
import ConfirmDialog from './MuiDialog/MuiDialog'
import MuiFab from './MuiFab/MuiFab'
import Model from './MuiModel/MuiModel'
import Notification from './MuiNotification/Notification'
import Paginate from './MuiPaginate/Paginate'
import Chip from './ChipButton/MuiChip'
import Statbox from './Statbox/Statbox'
import StatboxV2 from "./Statbox/StatboxV2"
import MainButton from './MuiButton/MainButton'
import Popup from './MuiModel/MuiModel'
import CategoryCard from "./DataDisplay/CategoryCard"
import MenuCard from './DataDisplay/MenuCard'
import MuiLoading from "./Loading/MuiLoading"
import AddToCart from './Cart/AddToCart'
import ActiveOrder from './DataDisplay/ActiveOrderCard'
import PopupOrder from "./MuiModel/PopupOrder"
import QtyButton from './MuiButton/QtyButton'

const Controls = {
  Input,
  MuiSelect,
  Button,
  Alert,
  ConfirmDialog,
  MuiFab,
  Model,
  Notification,
  Paginate,
  Chip,
  Statbox,
  StatboxV2,
  MainButton,
  Popup,
  CategoryCard,
  MenuCard,
  MuiLoading,
  AddToCart,
  ActiveOrder,
  PopupOrder,
  QtyButton
}

export default Controls
