import React from "react";
import Controls from "../components/Controls";
import MenuForm from "./MenuForm";
import {
  Container,
  Grid,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
// import {listCategories} from "../actions/categoryActions"
import {listMenus} from "../actions/menuActions"

const Menus = () => {
  const dispatch = useDispatch();
  const [recordForEdit, setRecordForEdit] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);

  // List Menus
  const menusList = useSelector((state) => state.menusList);
  const { menus } = menusList;

  // Create Category
  const menuCreate = useSelector((state) => state.menuCreate);
  const { success: createSuccess } = menuCreate;

  // Category Update
  const menuUpdate = useSelector((state) => state.menuUpdate);
  const { success: updateSuccess } = menuUpdate;

//Menu Delete
  const menuDelete = useSelector((state) => state.menuDelete);
  const { success: deleteSuccess } = menuDelete;

  //Confirm Dialog
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const menusLists = menus || [];

  React.useEffect(() => {
    dispatch(listMenus());
    if (createSuccess) {
      dispatch(listMenus());
    }

    if (createSuccess || updateSuccess) {
      setOpenPopup(false);
    }
    if (deleteSuccess) {
      setConfirmDialog(false);
    }
  }, [createSuccess,deleteSuccess,  updateSuccess, dispatch]);


  return (
    <Container>
      <Controls.Button
        color="primary"
        text="Add New Menu"
        sx={{ marginBottom: 1 }}
        onClick={() => {
          setOpenPopup(true);
          setRecordForEdit(null);
        }}
      />

      <Grid container spacing={1}>
        {menusLists &&
          menusLists.map((menu) => (
            <Grid item xs={12} sm={6} key={menu._id}>
              <Controls.MenuCard props={menu} />
            </Grid>
          ))}
      </Grid>

      {/* Confirm Dialog */}
      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {/* OPEN CATEGORY FROM ON POPUP */}
      <Controls.Popup
        open
        title="MENU FORM"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <MenuForm recordForEdit={recordForEdit} />
      </Controls.Popup>
    </Container>
  );
};

export default Menus;