import React from "react";
import Controls from "../components/Controls";

import { Box, Container, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {listOrders} from "../actions/orderActions"

const ActiveOrders = () => {
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = React.useState(false);

  // List Active Orders
  const orderList = useSelector((state) => state.orderList);
  const { orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Order Mark As Completed
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: deliverSuccess } = orderDeliver;

  //Confirm Dialog
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    dispatch(listOrders());

    if ( deliverSuccess) {
      setOpenPopup(false);
    }
  }, [ deliverSuccess, dispatch, userInfo._id]);

  const filteredData = orders || []

  return (
    <Container >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        <Box>
          <Typography sx={{ marginRight: 1 }} variant="h6">
            ORDERS
          </Typography>
        </Box>

      </Box>

      <Grid container spacing={1}>
        {
          filteredData.map((order) => (
            <Grid item xs={12} sm={4} key={order._id}>
              <Controls.ActiveOrder order={order} />
            </Grid>
          ))}
      </Grid>

      {/* Confirm Dialog */}
      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {/* OPEN EMPLOYEE FROM ON POPUP */}
      <Controls.Popup
        open
        title="ORDER TRACK"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      ></Controls.Popup>
    </Container>
  );
};

export default ActiveOrders;