import { createSlice } from '@reduxjs/toolkit'

export const menuUpdateSlice = createSlice({
  name: 'menuUpdate',
  initialState: {
    menu: {},
  },
  reducers: {
    menu_update_request: (state, action) => {
      return { loading: true }
    },
    menu_update_success: (state, action) => {
      return { loading: false, success: true, menu: action.payload }
    },
    menu_update_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
    menu_update_reset: (state, action) => {
      return { menu: {} }
    },
  },
})

export const {
  menu_update_request,
  menu_update_success,
  menu_update_fail,
  menu_update_reset,
} = menuUpdateSlice.actions

export default menuUpdateSlice.reducer