import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import React from 'react'

const Select = (props) => {
  const { label, name, value, error = null, size, onChange, options } = props
  return (
    <FormControl
      variant="outlined"
      fullWidth
      size={size || 'medium'}
      {...(error && { error: true })}
      sx={{ marginTop: 1 }}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} name={name} value={value} onChange={onChange}>

        {options.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default Select
