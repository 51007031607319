import * as React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

const IconChips = (props) => {
  const { icon, label, color } = props
  return (
    <Stack direction="row" spacing={1}>
      <Chip
        icon={icon}
        label={label}
        variant="contained"
        size="small"
        color={color || 'error'}
        sx={{
          marginBottom: -2,
          zIndex: 10,
        }}
      />
    </Stack>
  )
}
export default IconChips
