import { createSlice } from '@reduxjs/toolkit'

export const menuDeleteSlice = createSlice({
  name: 'menuDelete',
  initialState: {},
  reducers: {
    menu_delete_request: (state, action) => {
      return { loading: true }
    },
    menu_delete_success: (state, action) => {
      return { loading: false, success: true }
    },
    menu_delete_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
  },
})

export const {
  menu_delete_request,
  menu_delete_success,
  menu_delete_fail,
} = menuDeleteSlice.actions

export default menuDeleteSlice.reducer