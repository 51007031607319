import { createSlice } from '@reduxjs/toolkit'

export const orderDeleteSlice = createSlice({
  name: 'orderDelete',
  initialState: {},
  reducers: {
    order_delete_request: (state, action) => {
      return { loading: true }
    },
    order_delete_success: (state, action) => {
      return { loading: false, success: true }
    },
    order_delete_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
  },
})

export const { order_delete_request, order_delete_success, order_delete_fail } =
  orderDeleteSlice.actions

export default orderDeleteSlice.reducer