import axios from "axios";
import {
  order_create_request,
  order_create_success,
  order_create_fail,
} from "../reducers/orderReducers/orderCreateSlice";
import {
  order_details_request,
  order_details_success,
  order_details_fail,
} from "../reducers/orderReducers/orderDetailSlice";

import {
  order_list_request,
  order_list_success,
  order_list_fail,
} from "../reducers/orderReducers/orderListSlice";
import {
  order_deliver_request,
  order_deliver_success,
  order_deliver_fail,
} from "../reducers/orderReducers/orderDeliverSlice";
import {
  order_delivered_fail,
  order_delivered_request,
  order_delivered_success,
} from "../reducers/orderReducers/orderDeliveredSlice";

import {
  order_delete_request,
  order_delete_success,
  order_delete_fail,
} from "../reducers/orderReducers/orderDeleteSlice";

//create new order by customer
export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch(order_create_request());

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/orders`, order, config);

    dispatch(order_create_success(data));
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch(order_create_fail(error));
  }
};

//order details by id
export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch(order_details_request());

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders/${id}`, config);

    // const { orderItems } = data;

    // const sortedItems = orderItems.sort((a, b) => (a.rank > b.rank ? 1 : -1));

    dispatch(order_details_success(data));
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch(order_details_fail(error));
  }
};

//Delivered true orders
export const orderDelivered =
  (keyword = "", pageNumber = "") =>
  async (dispatch, getState) => {
    try {
      dispatch(order_delivered_request());

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/orders/delivered?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch(order_delivered_success(data));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch(order_delivered_fail(message));
    }
  };

//admin order pending order lists
export const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch(order_list_request());

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders`, config);

    dispatch(order_list_success(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch(order_list_fail(message));
  }
};

//Update to delivered
export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch(order_deliver_request());

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/orders/${order._id}/deliver`,
      {},
      config
    );

    dispatch(order_deliver_success(data));
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch(order_deliver_fail(error));
  }
};

// Delete Order
export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch(order_delete_request());

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/orders/${id}`, config);
    dispatch(order_delete_success());
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch(order_delete_fail(error));
  }
};