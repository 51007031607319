import React from "react";
import { useDispatch } from "react-redux";
import {useForm,Form} from "./useForm"
import Controls from "../components/Controls";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {createCategory,updateCategory} from "../actions/categoryActions"

import Grid from "@mui/material/Grid";



// Initial Form Values
const initialFormValues = {
  name: ""
};

const CategoryForm = (props) => {
  const dispatch = useDispatch();

  const { recordForEdit } = props;

  // Form Validation ->
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // Use Form ->
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFormValues,
    true,
    validate
  );

  React.useEffect(() => {
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [dispatch, recordForEdit, setValues]);

  // Submit Form Handler ->
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate())
      if (values && values._id) {
        dispatch(updateCategory(values));
      } else dispatch(createCategory(values));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controls.Input
            size="small"
            name="name"
            label="Category Name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
      </Grid>

      {recordForEdit ? (
        <Controls.Button
          style={{ marginTop: "8px" }}
          fullWidth
          text="Update Category"
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        />
      ) : (
        <div style={{ marginTop: "16px", display: "flex" }}>
          <Controls.Button
            size="large"
            color="success"
            fullWidth
            text="Submit"
            type="submit"
            endIcon={<KeyboardArrowRightIcon />}
          />
        </div>
      )}
    </Form>
  );
};

export default CategoryForm;