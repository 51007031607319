import React from "react";
import Controls from "../components/Controls";
import CategoryForm from "./CategoryForm";
import {
  Container,
  Grid,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {listCategories} from "../actions/categoryActions"

const Categories = () => {
  const dispatch = useDispatch();
  const [recordForEdit, setRecordForEdit] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);

  // List Categories
  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  // Create Category
  const categoryCreate = useSelector((state) => state.categoryCreate);
  const { success: createSuccess } = categoryCreate;

  // Category Update
  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const { success: updateSuccess } = categoryUpdate;

//   //Category Delete
//   const deleteCustomer = useSelector((state) => state.customerDelete);
//   const { success: deleteSuccess } = deleteCustomer;

  //Confirm Dialog
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const categoryLists = categories || [];

  React.useEffect(() => {
    dispatch(listCategories());
    if (createSuccess) {
      dispatch(listCategories());
    }

    if (createSuccess || updateSuccess) {
      setOpenPopup(false);
    }
    // if (deleteSuccess) {
    //   setConfirmDialog(false);
    // }
  }, [createSuccess,  updateSuccess, dispatch]);


  return (
    <Container>
      <Controls.Button
        color="primary"
        text="Add New Category"
        sx={{ marginBottom: 1 }}
        onClick={() => {
          setOpenPopup(true);
          setRecordForEdit(null);
        }}
      />

      <Grid container spacing={1}>
        {categoryLists &&
          categoryLists.map((category) => (
            <Grid item xs={12} sm={6} key={category._id}>
              <Controls.CategoryCard props={category} />
            </Grid>
          ))}
      </Grid>

      {/* Confirm Dialog */}
      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {/* OPEN CATEGORY FROM ON POPUP */}
      <Controls.Popup
        open
        title="CATEGORY FORM"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CategoryForm recordForEdit={recordForEdit} />
      </Controls.Popup>
    </Container>
  );
};

export default Categories;