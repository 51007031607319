import { TextField, InputAdornment } from '@mui/material'
import React from 'react'

const Input = (props) => {
  const {
    name,
    value,
    label,
    error = null,
    size,
    type,
    variant,
    multiline,
    onChange,
    icon,
    others,
  } = props

  return (
    <TextField
      sx={{ background: '#FFF' }}
      margin="dense"
      multiline={false || multiline}
      size={size || 'medium'}
      fullWidth
      type={type || 'string'}
      variant={variant || 'outlined'}
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      {...others}
      {...(error && { error: true, helperText: error })}
      InputProps={{
        ...(icon && {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }),
      }}
    />
  )
}

export default Input
