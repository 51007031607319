import React from 'react'
import { Button as MuiButton } from '@mui/material'

const Button = (props) => {
  const { text, size, color, varient, onClick, ...others } = props
  return (
    <MuiButton
    disableElevation
      size={size || 'medium'}
      color={color || 'primary'}
      variant={varient || 'contained'}
      onClick={onClick}
      {...others}
    >
      {text}
    </MuiButton>
  )
}

export default Button
