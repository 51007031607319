import { Grid } from "@mui/material";
import React from "react";

// import { listProducts } from "../../../actions/productActions";
// import { listUsers } from "../../../actions/userActions";
// import { listOrders } from "../../../actions/ordersActions";
import Controls from "../components/Controls";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const Dashboard = () => {

  // const productList = useSelector((state) => state.productList);
  // const { products } = productList;

  // React.useEffect(() => {
  //   dispatch(listProducts());
  // }, [dispatch]);

  // React.useEffect(() => {
  //   dispatch(listUsers());
  // }, [dispatch]);

  // React.useEffect(() => {
  //   dispatch(listOrders());
  // }, [dispatch]);

  return (
    <Grid  container spacing={1}>

<Grid item xs={12} md={6}>
        <Controls.StatboxV2
          title="POS"
          component={Link}
          to="/admin/pos"
          subtitle={
            <Controls.MainButton
              variant="contained"
              color="secondary"
              component={Link}
              to="/admin/pos"
              text="POS SYSTEM"
            />
          }
          icon={<PointOfSaleIcon sx={{ fontSize: "4.5rem" }} color="success" />}
        />
      </Grid>

<Grid item xs={12} md={6}>
        <Controls.Statbox
          title="MENUS"
          component={Link}
          to="/admin/menus"
          subtitle={
            <Controls.MainButton
              variant="contained"
              color="secondary"
              component={Link}
              to="/admin/menus"
              text="View Records"
            />
          }
          icon={<MenuBookIcon sx={{ fontSize: "4.5rem" }} color="success" />}
        />
      </Grid>

<Grid item xs={12} md={6}>
        <Controls.Statbox
          title="Category"
          component={Link}
          to="/admin/orders"
          subtitle={
            <Controls.MainButton
              variant="contained"
              color="secondary"
              component={Link}
              to="/admin/category"
              text="View Records"
            />
          }
          icon={<ShoppingCartIcon sx={{ fontSize: "4.5rem" }} color="success" />}
        />
      </Grid>

</Grid>
  );
};

export default Dashboard;