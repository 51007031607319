import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CartDashboard from "./CartDashboard"
import CartAddedItems from "./CartAddedItems"
import { Paper } from '@mui/material';
import {useSelector} from "react-redux"


export default function PosDashboard() {
    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <CartDashboard />
        </Grid>
        <Grid item xs={12} sm={4}>
            <Paper elevation={0} sx={{padding:1, backgroundColor: "#f9f9f9"}}>
                {cartItems.length === 0 ? "Cart Empty" : (
                     <CartAddedItems />
                )}
            </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}