import { Container } from '@mui/material'
import React from 'react'

export const useForm = (
  initialFormValues,
  validateOnChange = false,
  validate
) => {
  const [values, setValues] = React.useState(initialFormValues)
  const [errors, setErrors] = React.useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
    if (validateOnChange) {
      validate({ [name]: value })
    }
  }

  //Reset handle ->
  const handleReset = () => {
    setValues(initialFormValues)
    setErrors({})
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleReset,
  }
}

export const Form = (props) => {
  const { children, ...other } = props
  return (
    <Container maxWidth="xs" disableGutters>
      <form autoComplete="off" {...other}>
        {children}
      </form>
    </Container>
  )
}
