import { Box, Typography } from '@mui/material'
import React from 'react'

const Landing = () => {
  return (
    <Box sx={{marginTop:10}}>
        <Typography variant='h6'>

        Welcome to Waindo Landing Page 
        </Typography>
        
        </Box>
  )
}

export default Landing