import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@mui/material'
import Controls from '../Controls'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props

  return (
    // <Dialog open={confirmDialog.isOpen} sx={{ marginBottom: 20 }}>
    <Dialog open={confirmDialog.isOpen || false} sx={{ marginBottom: 20 }}>

      <DialogContent
        sx={{
          padding: 2,
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconButton
          disableRipple
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: '8rem',
            },
          }}
        >
          <NotListedLocationIcon />
        </IconButton>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Controls.Button
          disableElevation
          fullWidth
          color="custom"
          text="No"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <Controls.Button
          disableElevation
          fullWidth
          text="Yes"
          color="warning"
          onClick={confirmDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  )
}
