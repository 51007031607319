import * as React from 'react'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Snackbar } from '@mui/material'

const MuiAlert = ({ type, message }) => {
  const [open, setOpen] = React.useState(true)

  setTimeout(() => {
    setOpen(false)
  }, 3000)

  return (
    <Snackbar>
      <Collapse in={open}>
        <Alert
          severity={type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </Snackbar>
  )
}

export default MuiAlert
