import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useForm,Form} from "./useForm"
import Controls from "../components/Controls";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {createMenu,updateMenu} from "../actions/menuActions"
import {listCategories} from "../actions/categoryActions"

import Grid from "@mui/material/Grid";



// Initial Form Values
const initialFormValues = {
  name: "",
  category :"",
  description : "",
  price: ""
};

const MenuForm = (props) => {
  const dispatch = useDispatch();

   // List Categories
   const categoryList = useSelector((state) => state.categoryList);
   const { categories } = categoryList;

  const { recordForEdit } = props;

  // Form Validation ->
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // Use Form ->
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFormValues,
    true,
    validate
  );

  React.useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  React.useEffect(() => {
    if (recordForEdit !== null) {
      const { category } = recordForEdit;
      setValues({
        ...recordForEdit,
        category: category._id,
      });
    }
  }, [dispatch, recordForEdit, setValues]);

  // Submit Form Handler ->
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate())
      if (values && values._id) {
        dispatch(updateMenu(values));
      } else dispatch(createMenu(values));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controls.Input
          fullWidth
            size="small"
            name="name"
            label="Menu Name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: 0 }}>
          <Controls.MuiSelect
            size="small"
            name="category"
            label="Category"
            value={values.category}
            options={categories}
            onChange={handleInputChange}
            error={errors.category}
          />
        </Grid>

        <Grid item xs={12}>
          <Controls.Input
         
          fullWidth
            size="small"
            name="description"
            label="Menu Description"
            value={values.description}
            onChange={handleInputChange}
            error={errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <Controls.Input
          fullWidth
            size="small"
            name="price"
            label="Price"
            value={values.price}
            onChange={handleInputChange}
            error={errors.price}
          />
        </Grid>
        
      </Grid>

   

      {recordForEdit ? (
        <Controls.Button
          style={{ marginTop: "8px" }}
          fullWidth
          text="Update Menu"
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        />
      ) : (
        <div style={{ marginTop: "16px", display: "flex" }}>
          <Controls.Button
            size="large"
            color="success"
            fullWidth
            text="Submit"
            type="submit"
            endIcon={<KeyboardArrowRightIcon />}
          />
        </div>
      )}
    </Form>
  );
};

export default MenuForm;