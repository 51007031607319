import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, Form } from '../useForm'
import Controls from '../../components/Controls'
import { Box } from '@mui/system'
import { Paper, Typography } from '@mui/material'
import { Container } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../actions/userActions'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// Initial Form Values
const initialFormValues = {
  email: '',
  password: '',
}

// SIGN IN FORM
const Signin = () => {
  // Validate Function
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('email' in fieldValues)
      temp.email = fieldValues.email ? '' : '*This field is required.'

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : '*This field is required.'

    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }
  // End Validation Function

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFormValues,
    true,
    validate
  )

  const { email, password } = values

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, loading, error } = userLogin


  const navigate = useNavigate()
  const location = useLocation()

  // Customer Redirect
  const redirect = location.search ? location.search.split('=')[1] : '/customer'

  // Admin Redirect
  const adminRedirect = location.search
    ? location.search.split('=')[1]
    : '/admin'

  //Redirect to Signin
  const homeRedirect = location.search ? location.search.split('=')[1] : '/'

  // React useEffect --->
  React.useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      navigate(adminRedirect)
    } else if (userInfo) {
      navigate(homeRedirect)
    }
  }, [navigate, userInfo, redirect, adminRedirect, homeRedirect])
  // End React useEffect

  // Submit Handler
  const submitHandler = (e) => {
    e.preventDefault()
    if (validate()) dispatch(login(email, password))
  }
  // End Submit Handler
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', 
          minHeight: '80vh', 
        }}
      >
        <Paper
          sx={{
            padding: 2,
            '&. css-14bqk0y-MuiContainer-root': {
              padding: 0,
            },
          }}
          elevation={0}
        >
          {/* <Box
          component="img"
          sx={{ height: 130, marginBottom: 1.2 }}
          alt="Logo"
          src={process.env.PUBLIC_URL + 'foodsr.svg'}
        /> */}

          <Typography variant="h3">Sign In</Typography>

          <Form onSubmit={submitHandler}>
            <Controls.Input
              label="Email"
              value={values.email}
              name="email"
              onChange={handleInputChange}
              error={errors.email}
              size="large"
            />
            <Controls.Input
              label="Password"
              value={values.password}
              name="password"
              type="password"
              onChange={handleInputChange}
              error={errors.password}
            />
            {error && <Controls.Alert type="error" message={error} />}
            <Box>
              {loading ? (
                <Controls.Button
                  sx={{
                    marginTop: 1,
                    backgroundColor: '#3daf57',
                    '&:hover': {
                      backgroundColor: '#3daf57',
                      boxShadow: 'none',
                    },
                  }}
                  disabled
                  variant="contained"
                  disableElevation
                  fullWidth
                  size="large"
                  text="Loading"
                  type="submit"
                />
              ) : (
                <Controls.Button
                  sx={{
                    marginTop: 1,
                    backgroundColor: '#3daf57',
                    '&:hover': {
                      backgroundColor: '#3daf57',
                      boxShadow: 'none',
                    },
                  }}
                  variant="contained"
                  disableElevation
                  fullWidth
                  size="large"
                  text="Sign In"
                  type="submit"
                  endIcon={<ArrowForwardIosIcon />}
                />
              )}
            </Box>
          </Form>
        </Paper>
      </Box>
    </Container>
  )
}

export default Signin
