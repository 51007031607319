import React from "react";
import { Button as MuiButton } from "@mui/material";

const MainButton = (props) => {
  const { text, size, color, varient, onClick, ...others } = props;
  return (
    <MuiButton
      sx={{ minWidth: "190px" }}
      disableElevation
      size={size || "small"}
      color={color || "primary"}
      variant={varient || "contained"}
      onClick={onClick}
      {...others}
    >
      {text}
    </MuiButton>
  );
};

export default MainButton;