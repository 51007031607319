import { createSlice } from '@reduxjs/toolkit'

export const menuCreateSlice = createSlice({
  name: 'menuCreate',
  initialState: {
    menu: {},
  },
  reducers: {
    menu_create_request: (state, action) => {
      return { loading: true }
    },
    menu_create_success: (state, action) => {
      return { loading: false, success: true, menu: action.payload }
    },
    menu_create_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
    menu_create_reset: (state, action) => {
      return {}
    },
  },
})

export const {
  menu_create_request,
  menu_create_success,
  menu_create_fail,
  menu_create_reset,
} = menuCreateSlice.actions

export default menuCreateSlice.reducer