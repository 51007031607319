import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    custom: {
      main: "#28a745",
      contrastText: "#f9f9f9",
    },
    customGreen: {
      main: "#52b86a",
      contrastText: "#fff",
    },

    buttonGroup: {
      main: "#e3e3e3",
      contrastText: "#003049",
    },
    muiChip: {
      main: "#f50057",
      contrastText: "#fff",
    },
    customButton: {
      main: "#e1e5f2",
      contrastText: "#003049",
    },
    light: {
      main: "#def4d2",
      contrastText: "#003049",
    },
    customBlack: {
      main: "#c0eaa9",
      contrastText: "#003049",
    },
    cart: {
      main: "#80ed99",
      contrastText: "#000",
    },
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          // backgroundColor: '#FFF',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          // Set your desired background color for table head
          // backgroundColor: '#000',
        },
      },
    },
  },
});

export default theme;