import { createSlice } from '@reduxjs/toolkit'

export const menusSlice = createSlice({
  name: 'menus',
  initialState: {
    menus: [],
  },
  reducers: {
    menus_request: (state, action) => {
      return { loading: true, menus: [] }
    },
    menus_success: (state, action) => {
      return {
        loading: false,
        menus: action.payload,
      }
    },
    menus_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
  },
})

export const { menus_request, menus_success, menus_fail } =
  menusSlice.actions

export default menusSlice.reducer