// PaginationComponent.js
import React from 'react'
import Pagination from '@mui/material/Pagination'

const CustomPagination = ({ pages, currentPage, onPageChange }) => {
  return (
    <Pagination
      count={pages}
      page={currentPage}
      onChange={onPageChange}
      color="custom"
      size="medium"
      sx={{ marginTop: '15px' }}
    />
  )
}

export default CustomPagination
