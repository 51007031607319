import { createSlice } from "@reduxjs/toolkit";

export const orderDeliveredSlice = createSlice({
  name: "orderDelivered",
  initialState: {
    orders: [],
  },

  reducers: {
    order_delivered_request: (state, action) => {
      return { loading: true };
    },
    order_delivered_success: (state, action) => {
      return {
        loading: false,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    },

    order_delivered_fail: (state, action) => {
      return { loading: false, error: action.payload };
    },
    order_delivered_reset: (state, action) => {
      return { orders: [] };
    },
  },
});

export const {
  order_delivered_request,
  order_delivered_success,
  order_delivered_fail,
  order_delivered_reset,
} = orderDeliveredSlice.actions;

export default orderDeliveredSlice.reducer;