import React from "react";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Button, ButtonGroup, Paper } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// CART SYSTEM
import {
  addToCart,
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
} from "../../actions/cartActions";

import Controls from "../Controls";

const AddToCart = ({menu}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  React.useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [dispatch, userInfo, navigate]);

  //Add to cart
  const addToCartHandler = (menu, qty) => {
    dispatch(addToCart(menu, 1));
  };

  //IncreaseItemQty
  const increaseItemQuantity = (id) => {
    dispatch(increaseQuantity(id));
  };

  //decreaseItemQty
  const decreaseItemQuantity = (id) => {
    dispatch(decreaseQuantity(id));
  };

  //Remove Items
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;


  return (
    <Paper
      elevation={0}
      sx={
         { padding: "4px",  background: "#edf7f0" }
      }
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            marginRight: 1,
            maxWidth: "120px",
            maxHeight: "142px",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: "120px",
              height: "120px",
              border: "1px solid #FFF",
              borderRadius: "4.5px",
            }}
            src={menu.image}
          />
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.2rem",
              lineHeight: "1.2",
              marginBottom: 0.4,
            }}
          >
            {menu.name}
          </Typography>

         
            {/* <Typography
             variant="body1"
            >
              {menu.description}
            </Typography> */}
        
        
            <Typography
             variant="body2"
            >
              ¥{menu.price}
            </Typography>
         
         

          <Box
            sx={{
              marginTop: 1.2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {cartItems.find((cart) => cart._id === menu._id) ? (
              <>
                {cartItems.map((cartItem) =>
                  cartItem._id === menu._id ? (
                    <div key={cartItem._id}>
                      <ButtonGroup
                        color="custom"
                        variant="outlined"
                        disableElevation
                        size="medium"
                      >
                        <Button
                          disableElevation
                          onClick={() =>
                            cartItem.quantity === 1
                              ? removeFromCartHandler(cartItem._id)
                              : decreaseItemQuantity(cartItem._id)
                          }
                        >
                          {cartItem.quantity === 1 ? (
                            <HighlightOffIcon sx={{ color: "red" }} />
                          ) : (
                            <RemoveCircleIcon sx={{ color: "red" }} />
                          )}
                        </Button>
                        <Button
                          disableElevation
                          color="custom"
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            color: "custom",
                            padding: 0.8,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              backgroundColor: "#24963e",
                              border: "0.9px dashed #FFFFFF",
                              padding: 0.2,
                              borderRadius: "5px",
                              marginRight: 0.5,
                              width: "25px",
                              color: "#f9f9f9",
                              fontWeight: 500,
                            }}
                          >
                            {cartItem.quantity}
                          </Typography>
                          {cartItem.sku}
                        </Button>
                        <Button
                          disableElevation
                          onClick={() => {
                            increaseItemQuantity(cartItem._id);
                          }}
                        >
                          <AddCircleIcon fontSize="medium" color="custom" />
                        </Button>
                      </ButtonGroup>
                    </div>
                  ) : null
                )}
              </>
            ) : (
              <>
                <Button
                  endIcon={<ShoppingCartIcon />}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    addToCartHandler(menu._id);
                  }}
                  sx={{
                    backgroundColor: "#28a745",
                    "&:hover": {
                      backgroundColor: "#28a745",
                      boxShadow: "none",
                    },
                  }}
                >
                  Add to cart
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Paper>
  );
};

export default AddToCart;