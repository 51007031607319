import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

//Users & Auth Imports
import userLoginReducer from './reducers/userReducers/userSigninSlice'
import userRegisterReducer from './reducers/userReducers/userSignupSlice'

//Category
import categoriesListReducer from "./reducers/categoryReducers/categoriesSlice";
import categoryCreateReducer from "./reducers/categoryReducers/categoryCreateSlice";
import categoryUpdateReducer from "./reducers/categoryReducers/categoryUpdateSlice";

//MENUS
import menusListReducer from "./reducers/menuReducers/menusSlice"
import menuCreateReducer from "./reducers/menuReducers/menuCreateSlice"
import menuUpdateReducer from "./reducers/menuReducers/menuUpdateSlice"
import menuDeleteReducer from "./reducers/menuReducers/menuDeleteSlice"

//CART
// import cartReducer from "./reducers/cartReducers/cartSlice";
import cartReducer from "./reducers/cartReducers/cartSliceV2";

//Order Reducer
import orderCreateReducer from "./reducers/orderReducers/orderCreateSlice";
import orderListReducer from "./reducers/orderReducers/orderListSlice";
import OrderDetailsReducer from "./reducers/orderReducers/orderDetailSlice";
import orderDeliverReducer from "./reducers/orderReducers/orderDeliverSlice";
import orderDeliveredReducer from "./reducers/orderReducers/orderDeliveredSlice";
import orderDeleteReducer from "./reducers/orderReducers/orderDeleteSlice";

const middleware = [thunk]
const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,

    //Cart
    cart: cartReducer,

    //ORDER
    orderCreate: orderCreateReducer,
    orderList: orderListReducer,
    orderDetails: OrderDetailsReducer,
    orderDeliver: orderDeliverReducer,
    orderDeliveredList: orderDeliveredReducer,
    orderDelete: orderDeleteReducer,

    // Categories
    categoryList: categoriesListReducer,
    categoryCreate: categoryCreateReducer,
    categoryUpdate: categoryUpdateReducer,

    // Menus
    menusList : menusListReducer,
    menuCreate: menuCreateReducer,
    menuUpdate: menuUpdateReducer,
    menuDelete: menuDeleteReducer

  },
  preloadedState: {},
  middleware,
})

export default store

