import * as React from 'react'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'

export default function FloatingActionButtonSize() {
  return (
    <Box
      sx={{
        zIndex: 10,
        position: 'absolute',
        bottom: `7%`,
        right: 15,
        // '& > :not(style)': { m: 1 },
      }}
    >
      <Fab
        size="medium"
        color="secondary"
        aria-label="add"
        component={Link}
        to="/admin"
      >
        <HomeIcon />
      </Fab>
    </Box>
  )
}
