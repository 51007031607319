import React from 'react'
import {
  AppBar,
  Toolbar,
  Container,
  Button,
} from '@mui/material'
import { Link, } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'



// NEW CODE
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import Controls from '../Controls'

const Header = () => {

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  // Button Group code
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  //---CARTS---
  //   const cart = useSelector((state) => state.cart)
  //   const { cartItems } = cart

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <>
      <AppBar elevation={0} position="fixed">
        <Toolbar
          sx={{
            // padding: { xs: 0 },
            // backgroundColor: '#28a745',
            backgroundColor: '#e3e3e3',
          }}
        >
          <Container
          
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ flexGrow: 1 }}
                // component={Link}
                // to={userInfo.isAdmin ? '/admin' : '/customer'}
            >
              <Box
                component="img"
                sx={{ height: 55, marginTop: 0.7, padding: 0.4 }}
                alt="Logo"
                src={process.env.PUBLIC_URL + '/logo192.png'}
              />
              <Box
                // component={Link}
                // to={userInfo && userInfo.isAdmin ? '/admin' : '/'}
              >
              {/* <Typography color="primary" variant="h5">
                Header
              </Typography> */}
              </Box>

           
            </Box>       
              <React.Fragment>
                <ButtonGroup ref={anchorRef} aria-label="split button">
                  <Button
                    color="inherit"
                    variant="contained"
                    sx={{
                      background: '#3daf57',
                      textTransform: 'none',
                      boxShadow: 'none',
                      paddingLeft: 1.2,
                      paddingRight: 1,
                      '&:hover': {
                        backgroundColor: '#3daf57',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    {userInfo && userInfo.email}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleToggle}
                    sx={{
                      background: '#68c17c',
                      boxShadow: 'none',

                      '&:hover': {
                        backgroundColor: '#68c17c',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            <MenuItem
                              onClick={logoutHandler}
                              component={Link}
                              to="/"
                              sx={{ color: 'red' }}
                            >
                              <ListItemIcon>
                                <Logout
                                  fontSize="medium"
                                  sx={{ color: 'red' }}
                                />
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
           
          </Container>
        </Toolbar>
      </AppBar>

      {/* <Box>{location.pathname !== "/customer" && <Controls.MuiFab />}</Box> */}

      <Box>
        <Controls.MuiFab />
      </Box>
    </>
  )
}

export default Header
