import React from 'react'
import { Container } from '@mui/system'
import { Box } from '@mui/material'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Signin from './Pages/signin/SignIn'
import AdminDashboard from "./Pages/AdminDashboard"
// import CartDashboard from "./Pages/CartDashboard"
// import PosDashboard from './Pages/PosDashboard'
// import Signup from './pages/signup/Signup'
import Header from './components/Header/Header'
import Landing from './Pages/Landing'
import Categories from './Pages/Categories'
import Menus from "./Pages/Menus"
import OrdersTab from './Pages/OrdersTab'

//DYANMIC HEADER
// import { useSelector } from 'react-redux'

const App = () => {
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;
  return (
    <Router>
      <Container
        maxWidth="lg"
        sx={{
          '& .MuiContainer-root': {
            padding: 0,
          },
        }}
      >
        <Box sx={{ marginBottom: 3, marginTop:10 }}>
          <Header />
        </Box>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/signin" element={<Signin />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/pos" element={<OrdersTab />} />
          {/* <Route path="/admin/pos" element={<PosDashboard />} /> */}
          <Route path="/admin/category" element={<Categories />} />
          <Route path="/admin/menus" element={<Menus />} />
        </Routes>
      </Container>
    </Router>
  )
}

export default App
