import { createSlice } from '@reduxjs/toolkit'

export const categoryCreateSlice = createSlice({
  name: 'categoryCreate',
  initialState: {
    category: {},
  },
  reducers: {
    category_create_request: (state, action) => {
      return { loading: true }
    },
    category_create_success: (state, action) => {
      return { loading: false, success: true, category: action.payload }
    },
    category_create_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
    category_create_reset: (state, action) => {
      return {}
    },
  },
})

export const {
  category_create_request,
  category_create_success,
  category_create_fail,
  category_create_reset,
} = categoryCreateSlice.actions

export default categoryCreateSlice.reducer