import { createSlice } from '@reduxjs/toolkit'

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
  },
  reducers: {
    categories_request: (state, action) => {
      return { loading: true, categories: [] }
    },
    categories_success: (state, action) => {
      return {
        loading: false,
        categories: action.payload,
      }
    },
    categories_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
  },
})

export const { categories_request, categories_success, categories_fail } =
  categoriesSlice.actions

export default categoriesSlice.reducer