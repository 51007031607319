import { createSlice } from '@reduxjs/toolkit'

export const categoryUpdateSlice = createSlice({
  name: 'categoryUpdate',
  initialState: {
    product: {},
  },
  reducers: {
    category_update_request: (state, action) => {
      return { loading: true }
    },
    category_update_success: (state, action) => {
      return { loading: false, success: true, category: action.payload }
    },
    category_update_fail: (state, action) => {
      return { loading: false, error: action.payload }
    },
    category_update_reset: (state, action) => {
      return { category: {} }
    },
  },
})

export const {
  category_update_request,
  category_update_success,
  category_update_fail,
  category_update_reset,
} = categoryUpdateSlice.actions

export default categoryUpdateSlice.reducer