import React from "react";
import { Box, Paper, Typography } from "@mui/material";

const StatBox = ({ title, subtitle, icon, buttonIcon, to }) => {
  return (
    <Paper
      sx={{
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        backgroundImage:
          "linear-gradient(178.6deg,  #f5f7fa 23.3%, rgb(232, 245, 253) 109.6%)",
      }}
      elevation={0}
    >
      <Box sx={{ display: "flex", alignItems: "center", padding: 1 }}>
        <Box>
          <Box
            sx={{
              marginRight: 1,
            }}
          >
            {icon}
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 0.5 }}>
            {title}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: "15px" }}>
            {subtitle}
          </Typography>
          {buttonIcon}
          {/* </Button> */}
        </Box>
      </Box>
    </Paper>
  );
};

export default StatBox;