import axios from 'axios'
import {
  login_fail,
  login_request,
  login_success,
  logout as logoutSlice,
} from '../reducers/userReducers/userSigninSlice'
import {
  user_register_request,
  user_register_success,
  user_register_fail,
} from '../reducers/userReducers/userSignupSlice'

// import {
//   user_profile_request,
//   user_profile_success,
//   user_profile_fail,
// } from '../reducers/userReducers/getUserProfileSlice'

// import {
//   users_list_request,
//   users_list_success,
//   users_list_fail,
// } from '../reducers/userReducers/usersListSlice'
// import {
//   user_delete_request,
//   user_delete_success,
//   user_delete_fail,
// } from '../reducers/userReducers/userDeleteSlice'
// import {
//   update_user_request,
//   update_user_success,
//   update_user_fail,
// } from '../reducers/userReducers/userUpdateSlice'

//user login reducer --->
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(login_request())

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/users/login',
      { email, password },
      config
    )

    dispatch(login_success(data))

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(login_fail(error))
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('cartItems')
  dispatch(logoutSlice())
}

//user register reducer --->
export const register = (username, email, password) => async (dispatch) => {
  try {
    dispatch(user_register_request())

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/users',
      { username, email, password },
      config
    )

    dispatch(user_register_success(data))
    dispatch(login_success(data))

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(user_register_fail(error))
  }
}

// CREATE A USER ->
// export const register = (values) => async (dispatch, getState) => {
//   try {
//     dispatch(user_register_request())

//     const {
//       userLogin: { userInfo },
//     } = getState()

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }

//     const { data } = await axios.post(`/api/users/`, values, config)
//     dispatch(user_register_success(data))
//   } catch (err) {
//     const error =
//       err.response && err.response.data.message
//         ? err.response.data.message
//         : err.message
//     dispatch(user_register_fail(error))
//   }
// }

//Update User
// export const updateUser = (user) => async (dispatch, getState) => {
//   try {
//     dispatch(update_user_request())

//     const {
//       userLogin: { userInfo },
//     } = getState()

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }

//     const { data } = await axios.put(`/api/users/${user._id}`, user, config)
//     dispatch(update_user_success(data))
//   } catch (err) {
//     const error =
//       err.response && err.response.data.message
//         ? err.response.data.message
//         : err.message
//     dispatch(update_user_fail(error))
//   }
// }

//get user profile
// export const getUserProfile = (id) => async (dispatch, getState) => {
//   try {
//     dispatch(user_profile_request())

//     const {
//       userLogin: { userInfo },
//     } = getState()

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }

//     const { data } = await axios.get(`/api/users/${id}`, config)

//     dispatch(user_profile_success(data))
//   } catch (err) {
//     const error =
//       err.response && err.response.data.message
//         ? err.response.data.message
//         : err.message
//     dispatch(user_profile_fail(error))
//   }
// }

// export const listUsers = () => async (dispatch, getState) => {
//   try {
//     dispatch(users_list_request())

//     const {
//       userLogin: { userInfo },
//     } = getState()

//     const config = {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }

//     const { data } = await axios.get(`/api/users/`, config)

//     dispatch(users_list_success(data))
//   } catch (err) {
//     const error =
//       err.response && err.response.data.message
//         ? err.response.data.message
//         : err.message
//     dispatch(users_list_fail(error))
//   }
// }

// export const deleteUser = (id) => async (dispatch, getState) => {
//   try {
//     dispatch(user_delete_request())

//     const {
//       userLogin: { userInfo },
//     } = getState()

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${userInfo.token}`,
//       },
//     }

//     const { data } = await axios.delete(`/api/users/${id}`, config)

//     dispatch(user_delete_success())
//   } catch (err) {
//     const error =
//       err.response && err.response.data.message
//         ? err.response.data.message
//         : err.message
//     dispatch(user_delete_fail(error))
//   }
// }
