import React from "react";
import Controls from "../components/Controls";
import { removeFromCart } from "../actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import {createOrder} from "../actions/orderActions"
import { removeCartList } from "../actions/cartActions";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { useLocation, useNavigate } from "react-router-dom";

//Date Picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//PRODUCT TABLES IMPORT
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";

const Cart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  //After Place Order
  const orderCreate = useSelector((state) => state.orderCreate);
  const { success } = orderCreate;

  // Customer Redirect
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/admin/pos";

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

  //Redirect
  React.useEffect(() => {
    if (success) {
      dispatch(removeCartList);
      navigate(redirect);
      window.location.reload();
    }
    if (cartItems.length === 0) {
      navigate("/admin/pos");
    }
  }, [success, navigate, redirect, dispatch, cartItems.length]);

  //Remove Items
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  //Custom Message
  const [customMessage, setCustomMessage] = React.useState("");

  //Delivery Date
  const [deliveryDate, setDeliveryDate] = React.useState(dayjs(new Date()));
  const handleChange = (newValue) => {
    setDeliveryDate(newValue);
  };

  //Place Order
  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        customMessage,
        deliveryDate,
      })
    );
  };

  return (
    <Container  >
        <Typography variant="h5" align="center" color="primary">
          Cart
        </Typography>
      <Paper elevation={0}>
        <TableContainer>
          <Table
            aria-label="Products"
            size="small"
            stickyHeader
            sx={{ border: "1px solid #ced4da" }}
          >
            {/* ---TABLE HEAD--- */}
            <TableHead
              sx={{
                "& .MuiTableCell-root": {
                  background: "#ced4da",
                  paddingTop: 1.2,
                  paddingBottom: 1.2,
                },
              }}
            >
              <TableRow>
                <TableCell>PRODUCT</TableCell>
                <TableCell>QTY</TableCell>
                <TableCell>PRICE</TableCell>
                <TableCell sx={{ textAlign: "center" }}>REMOVE</TableCell>
              </TableRow>
            </TableHead>

            {/* ---TABLE BODY--- */}
            <TableBody>
              {cartItems.map((product, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ced4da",
                      padding: "0 14px",
                    }}
                  >
                    {product.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ced4da",
                      padding: "0 14px",
                    }}
                  >
                    {product.quantity} {product.sku}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ced4da",
                      padding: "0 14px",
                    }}
                  >
                    {product.price}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ced4da",
                      padding: "0 14px",
                      textAlign: "center",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeFromCartHandler(product._id)}
                    >
                      <HighlightOffIcon color="warning" size="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* )} */}
      </Paper>
      <Box>
        
        <Box sx={{display: "flex",  marginTop: 1, alignItems: "center", alignContent:"right"}}>
        <Typography variant="body" align="right">
                Subtotal ({cartItems.reduce((acc, item) => acc + item.quantity, 0)})
                items
              </Typography>
              
      <Typography sx={{marginLeft: 2}}>
        <strong>
        {cartItems
                .reduce((acc, item) => acc + item.quantity * item.price, 0)
                .toFixed(2)}
                  円
        </strong>
    
      </Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ marginTop: 2, marginBottom: -0.5 }}>
        <strong>SPECIAL NOTE</strong>
      </Typography>
      {cartItems.length > 0 && (
        <Controls.Input
          sx={{ marginTop: 1.8 }}
          multiline={true}
          label="Custom Message"
          text="Custom Message"
          onChange={(e) => setCustomMessage(e.target.value)}
        />
      )}

      {/* <Box sx={{ marginTop: 1.2, width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            sx={{
              width: "100%",
            }}
            label="Select Delivery Date "
            inputFormat="MM/DD/YYYY"
            value={deliveryDate}
            onChange={handleChange}
            slotProps={(params) => (
              <TextField
                {...params}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    padding: 1.4,
                    paddingLeft: 1.4,
                  },
                  "& .MuiSvgIcon-root": {
                    color: "red",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box> */}
      <Controls.Button
        endIcon={<SendIcon />}
        size="large"
        onClick={placeOrderHandler}
        sx={{
          position: "sticky",
          size: "medium",
          width: "100%",
          marginTop: 1.8,
          background: "#28a745",
          boxShadow: "none",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#28a745",
            boxShadow: "none",
          },
        }}
        label="Continue to"
        text="Add New Order"
      />
    </Container>
  );
};

export default Cart;