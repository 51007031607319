import { createSlice } from "@reduxjs/toolkit";

export const orderDetailSlice = createSlice({
  name: "order",
  items: "items",
  initialState: {
    // order: {},
  },
  reducers: {
    order_details_request: (state, action) => {
      return { loading: true };
    },
    order_details_success: (state, action) => {
      return {
        loading: false,
        order: action.payload,
      };
    },
    order_details_fail: (state, action) => {
      return { loading: false, error: action.payload };
    },
  },
});

export const {
  order_details_request,
  order_details_success,
  order_details_fail,
} = orderDetailSlice.actions;

export default orderDetailSlice.reducer;