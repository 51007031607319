import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Stack,
  Fab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Popup = (props) => {
  const { title, children, openPopup, setOpenPopup } = props;
  return (
    <Dialog open={openPopup} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" sx={{ flexGrow: 1, alignContent: "left" }}>
            {title}
          </Typography>
          <Fab
            sx={{ boxShadow: "none" }}
            size="small"
            color="error"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </Fab>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ padding: 1 }} dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Popup;