import axios from 'axios'
import {
  categories_request,
  categories_success,
  categories_fail,
} from '../reducers/categoryReducers/categoriesSlice'

import {
  category_create_request,
  category_create_success,
  category_create_fail,
} from '../reducers/categoryReducers/categoryCreateSlice'

import {
  category_update_request,
  category_update_success,
  category_update_fail,
} from '../reducers/categoryReducers/categoryUpdateSlice'

// LIST CATEGORIES ->
export const listCategories = () => async (dispatch) => {
  try {
    dispatch(categories_request())
    const { data } = await axios.get(`/api/category`)
    dispatch(categories_success(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(categories_fail(error))
  }
}

// CREATE A NEW CATEGORY ->
export const createCategory = (values) => async (dispatch, getState) => {
  try {
    dispatch(category_create_request())

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/category/`, values, config)
    dispatch(category_create_success(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(category_create_fail(error))
  }
}

//Update Product
export const updateCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch(category_update_request())

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/category/${category._id}`,
      category,
      config
    )
    dispatch(category_update_success(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(category_update_fail(error))
  }
}