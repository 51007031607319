import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";

import {  Box,  Typography, Button } from "@mui/material";

import { getOrderDetails } from "../actions/orderActions";
import { deliverOrder } from "../actions/orderActions";

import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import Controls from "../components/Controls";

//Order Details
const OrderDetail = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: deliverSuccess } = orderDeliver;

  React.useEffect(() => {
    if (!userInfo) {
      navigate("/");
      if (deliverSuccess) {
        setConfirmDialog(false);
      }
    } else {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, id, navigate, deliverSuccess, userInfo]);

  const deliverHandler = (order) => {
    dispatch(deliverOrder(order));
    if (deliverSuccess) {
      setConfirmDialog(false);
    }
  };

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  //Print Functionality
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order Detail",
  });

  return (
    <Box
      sx={{
        // width: "58mm",
        // height: "100%",
        "@media print": {
            "@page":{
                // size: "58mm",
                margin: 0,
            },
            // padding: 1,
            color: "black",
            backgroundColor: "white",
            margin: "0 auto",
        },
      }}
      ref={componentRef}
    >
      <Box>
      <Typography variant="h6" align="center">Waindo Kiba</Typography>
        <Typography variant="body2" align="center">Multi Cuisine Indian Restaurant</Typography>
      </Box>

    {order &&
            order.orderItems.map((order) => (

              <Box sx={{display: "flex", alignItems: "center", borderBottom: "1px dashed gray"}}>
              <Box sx={{marginRight: "auto"}}>
              <Typography variant="body1">{order.name}</Typography>
              <Typography variant="body2">{order.quantity} x ${order.price}</Typography>
              </Box>
              <Box sx={{marginLeft: "auto"}}>
              <Typography variant="subtit"><strong>{order.quantity * order.price}</strong></Typography>
              </Box>
              </Box>
          ))}

<Box sx={{ paddingTop: "5px"}}>
<Typography variant="body2"  textAlign="right">
        SubTotal : ¥{order && order.itemsPrice}
      </Typography>
      <Typography variant="body2"  textAlign="right">
        Tax : ¥{order && order.taxPrice}
      </Typography>
      <Typography  textAlign="right">
        <strong>Total :</strong> ¥{order && order.totalPrice}
      </Typography>
</Box>

<Box>
  <Typography align="center" sx={{marginBottom:1}}>
    Thank You and See You Again..!
  </Typography>
</Box>
    
    {userInfo.isAdmin && (
        <Button
        disableElevation
          variant="contained"
          size="small"
          onClick={handlePrint}
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          Print
        </Button>
      )}
      {userInfo.isAdmin && order && order.isDelivered === false ? (
        <Button
          sx={{
            marginLeft: 1,
            "@media print": {
              display: "none",
            },
          }}
          disableElevation
          variant="contained"
          color="success"
          size="small"
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "Are You Sure to Mark Completed ?",
              subTitle: "You cannot undo this operation",
              onConfirm: () => {
                deliverHandler(order);
              },
            });
          }}
        >
          Mark as Completed
        </Button>
      ) : (
        ""
      )}

      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Box>
  );
};

export default OrderDetail;