import React from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Controls from "../Controls";
import OrderDetail from "../../Pages/OrderDetails";

import { useDispatch } from "react-redux";

const ActiveOrder = ({ order }) => {
  const dispatch = useDispatch();

  const [recordForEdit, setRecordForEdit] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");

  const openInPopup = (order) => {
    setRecordForEdit(order);
    setOpenPopup(true);
  };

  //Confirm Dialog
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // Employee Delete Handler
//   const deleteHandler = (id) => {
//     dispatch(employeeDelete(id));
//     setConfirmDialog(false);
//   };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          borderRadius: 2,
          border: "1px solid #def4d2",
          backgroundColor: "#f6fcf4",
          "& .MuiCardContent-root:last-child": {
            paddingBottom: "8px",
            paddingTop: "1px",
          },
          "& .MuiCardContent-root": {
            padding: "8px",
          },
        }}
      >
        <CardContent>
          <Typography variant="h6">{order.name}</Typography>
    
          <Box sx={{  marginTop: 1 }}>
            <Controls.Button
              sx={{ marginRight: 1 }}
              fullWidth
              variant="contained"
              color="customBlack"
              size="small"
                text={`${moment(
                order.createdAt
              ).format("LT")} - ${moment(order.deliveredAt).format("L")}`}
            />
            <Box sx={{marginTop:1}}>
                <Controls.Button 
                size="small"
                variant="contained"
                fullWidth
                color="custom"
                text = "View Order" 
                onClick={() => {
                              setOpenPopup(true);
                              setOrderId(order._id);
                            }} 
                />
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Controls.Popup
        open
        title="ORDER DETAILS"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OrderDetail id={orderId}/>
      </Controls.Popup>
    </>
  );
};

export default ActiveOrder;