import React from "react";
import { Box, Paper, Typography } from "@mui/material";

const Statbox = ({ title, subtitle, icon, buttonIcon, to, ...others }) => {
  return (
    <Paper
      sx={{
        borderRadius: "8px",
        // backgroundColor: "#ebf2fa",
        backgroundColor: "#eff8f1",
        display: "flex",
        justifyContent: "center"
      }}
      elevation={0}
    >
      <Box sx={{ display: "flex", alignItems: "center", padding: 0.5 }}>
        <Box>
          <Box
            sx={{
              marginRight: 1,
            }}
          >
            {icon}
          </Box>
        </Box>
        <Box>
          <Typography variant="h6">{title}</Typography>
          {/* <Typography variant="h6" sx={{ fontSize: "15px" }}> */}
          {subtitle}
          {/* </Typography> */}
          {buttonIcon}
          {/* </Button> */}
        </Box>
      </Box>
    </Paper>
  );
};

export default Statbox;