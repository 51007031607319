export const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };
  
  export const updateCart = (state) => {
    const itemsPrice = state.cartItems.reduce(
      (acc, item) => acc + (item.price * 100 * item.quantity) / 100,
      0
    );
    state.itemsPrice = addDecimals(itemsPrice);
  
    // Calculate the tax price
    const taxPrice = 0.15 * itemsPrice;
    state.taxPrice = addDecimals(taxPrice);
  
    const totalPrice = itemsPrice + taxPrice;
    // Calculate the total price
    state.totalPrice = addDecimals(totalPrice);
  
    // Save the cart to localStorage
    localStorage.setItem('cart', JSON.stringify(state));
  
    return state;
  };