import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Stack,
  Fab,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const Popup = (props) => {
  const { title, children, openPopup, setOpenPopup } = props
  return (
    <Dialog open={openPopup} maxWidth="xs">
      <DialogTitle>
        <Stack direction="row">
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>

          <Fab
            sx={{ boxShadow: 'none' }}
            size="small"
            color="error"
            onClick={() => {
              setOpenPopup(false)
            }}
          >
            <CloseIcon fontSize="medium" />
          </Fab>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}

export default Popup
