import axios from 'axios'
import {
menu_create_request,
menu_create_success,
menu_create_fail
} from '../reducers/menuReducers/menuCreateSlice'

import {
menus_request,
menus_success,
menus_fail
} from '../reducers/menuReducers/menusSlice'

import {
menu_update_request,
menu_update_success,
menu_update_fail
} from '../reducers/menuReducers/menuUpdateSlice'

import {
  menu_delete_request,
  menu_delete_success,
  menu_delete_fail,
} from "../reducers/menuReducers/menuDeleteSlice";

// LIST Menus ->
export const listMenus = () => async (dispatch) => {
  try {
    dispatch(menus_request())
    const { data } = await axios.get(`/api/menu`)
    dispatch(menus_success(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(menus_fail(error))
  }
}

// CREATE A NEW MENU ->
export const createMenu = (values) => async (dispatch, getState) => {
  try {
    dispatch(menu_create_request())

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/menu/`, values, config)
    dispatch(menu_create_success(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(menu_create_fail(error))
  }
}

//Update MENU
export const updateMenu = (menu) => async (dispatch, getState) => {
  try {
    dispatch(menu_update_request())

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/menu/${menu._id}`,
      menu,
      config
    )
    dispatch(menu_update_success(data))
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    dispatch(menu_update_fail(error))
  }
}

// Delete Menu
export const deleteMenu = (id) => async (dispatch, getState) => {
  try {
    dispatch(menu_delete_request());

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/menu/${id}`, config);
    dispatch(menu_delete_success());
  } catch (err) {
    const error =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch(menu_delete_fail(error));
  }
};

