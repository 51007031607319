import React from "react";
import {
  Card,
  CardContent,
  Button,
  ButtonGroup,
  Typography,
  Box,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Controls from "../Controls";

// import { deleteCustomer } from "../../../actions/customerActions";
import CategoryForm from "../../Pages/CategoryForm";

// import { useDispatch } from "react-redux";

const CustomerData = ({ props }) => {
//   const dispatch = useDispatch();

  const [recordForEdit, setRecordForEdit] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);

  const openInPopup = (props) => {
    setRecordForEdit(props);
    setOpenPopup(true);
  };

  //Confirm Dialog
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // Employee Delete Handler
//   const deleteHandler = (id) => {
//     dispatch(deleteCustomer(id));
//     setConfirmDialog(false);
//   };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          borderRadius: 2,
          border: "1px solid #def4d2",
          backgroundColor: "#f6fcf4",

          "& .MuiCardContent-root:last-child": {
            paddingBottom: "8px",
            paddingTop: "1px",
          },
          "& .MuiCardContent-root": {
            padding: "8px",
          },
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: "450" }}>
            {props.name}
          </Typography>


          <Box sx={{ display: "flex", marginTop: 0.8 }}>
        
            <Box>
              <ButtonGroup
               
                variant="outlined"
                size="small"
                disableElevation
              >
                {/* <Button
                  size="small"
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are You Sure to Delete Category ?",
                      subTitle: "You cannot undo this operation",
                      onConfirm: () => {
                        // deleteHandler(props._id);
                      },
                    });
                  }}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </Button> */}
                <Button size="small">
                  <EditIcon
                    color="success"
                    onClick={() => openInPopup(props)}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Controls.ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Controls.Popup
        open
        title="Customer Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CategoryForm recordForEdit={recordForEdit} />
      </Controls.Popup>
    </>
  );
};

export default CustomerData;