import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PosDashboard from "./PosDashboard";
import ActiveOrders from "./ActiveOrders";
import CompletedOrders from "./CompletedOrders"

export default function OrdersTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Array of components to render on different tabs
  const tabComponents = [
    <PosDashboard key={0} />,
    <ActiveOrders key={1} />,
    <CompletedOrders key={2} />,
  ];

  return (
    <Box sx={{ width: "100%", backgroundColor: "custom", marginTop:-1 }}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab sx={{ backgroundColor: "#f9f9f9" }} label="POS" />
        <Tab sx={{ backgroundColor: "#f9f9f9" }} label="ACTIVE ORDERS" />
        <Tab sx={{ backgroundColor: "#f9f9f9" }} label="HISTORY" />
      </Tabs>
      {tabComponents[value]}
    </Box>
  );
}