import { createSlice } from '@reduxjs/toolkit'
import { updateCart } from '../../utils/cartUtils';

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: cartItemsFromStorage,
  },
  reducers: {
    cart_addItem: (state, action) => {
      const item = action.payload
      const existItem = state.cartItems.find((x) => x._id === item._id)

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x._id === existItem._id ? item : x
          ),
        }
      } else {
        // return {
        //   ...state,
        //   success: true,
        //   cartItems: [...state.cartItems, item],
        // }
     

        state.cartItems = [...state.cartItems, item];
        return updateCart(state, item)
      }

  
    //   cart_addItem: (state, action) => {
    //     const item = action.payload;
    //     const existItem = state.cartItems.find((x) => x._id === item._id);
    
    //     if (existItem) {
    //       return {
    //         ...state,
    //         cartItems: state.cartItems.map((x) =>
    //           x._id === existItem._id ? item : x
    //         ),
    //       };
    //     } else {
    //       return updateCart(state, item);
    //     }
      
    },

    cart_increaseQuantity: (state, action) => {
      const updatedCartItems = state.cartItems.map((item) =>
        item._id === action.payload
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )

      return {
        ...state,
        cartItems: updatedCartItems,
      }
    },

    cart_decreaseQuantity: (state, action) => {
      const updatedCartItems = state.cartItems.map((item) =>
        item._id === action.payload
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )

      return {
        ...state,
        cartItems: updatedCartItems,
      }
    },

    cart_removeItem: (state, action) => {
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x._id !== action.payload),
      }
    },

    cart_removeCartList: (state, action) => {
      return {
        ...state,
        cartItems: [],
      }
    },
  },
})

export const {
  cart_addItem,
  cart_removeItem,
  cart_removeCartList,
  cart_increaseQuantity,
  cart_decreaseQuantity,
} = cartSlice.actions

export default cartSlice.reducer