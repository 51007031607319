import {
    cart_addItem,
    cart_removeItem,
    cart_removeCartList,
    cart_increaseQuantity,
    cart_decreaseQuantity,
  } from "../reducers/cartReducers/cartSlice";
  import axios from "axios";
  
  //Add to cart
  export const addToCart = (id, qty) => async (dispatch, getState) => {
    const { data } = await axios.get(`/api/menu/${id}`);
    const cartItem = {
      _id: data._id,
      name: data.name,
      quantity: qty,
      price: data.price,
    };
    dispatch(cart_addItem(cartItem));
  
    localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };
  
  //Increase Item Quantity
  export const increaseQuantity = (id) => (dispatch, getState) => {
    dispatch(cart_increaseQuantity(id));
    localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };
  
  //decrease Item Quantity
  export const decreaseQuantity = (id) => (dispatch, getState) => {
    dispatch(cart_decreaseQuantity(id));
    localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };
  
  //Remove from cart
  export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch(cart_removeItem(id));
    localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };
  
  //Reset Cart List
  export const removeCartList = (dispatch, getState) => {
    dispatch(cart_removeCartList);
    localStorage.removeItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };